<template>
    <v-card>
        <v-toolbar class="darken-1" color="blue" dark>
            <v-toolbar-title>
                Details
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <th>
                        Check In Date
                    </th>
                    <th>
                        Check Out Date
                    </th>
                    <th>
                        Check Out Status
                    </th>
                    <!--<th>
                        roomList
                    </th>-->
                    <th>
                        Room Logs
                    </th>
                    <tbody>
                    <tr v-for="branch in branchData.hotelCustomerLogList" :key="branch.checkInDate">
                        <td>
                            {{branch.checkInDate | moment("DD-MMM-YYYY") }}
                        </td>

                        <td>
                            {{branch.checkOutDate | moment("DD-MMM-YYYY")}}
                        </td>

                        <td>
                            {{branch.checkoutStatus}}
                        </td>

                        <td>
              <span v-for="log in branch.roomLogs" :key="log">
                {{log}}
              </span>
                        </td>
                    </tr>

                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "HotelTrackingDetails",
            props: ["index","branchData"],
        data() {
            return {
            }
        },
        destroyed() {
        },
        mounted() {
            // console.log("HotelTrackingDetails data",this.branchData)
        },
        methods: {}
    };
</script>

<style scoped lang="scss">
td{
    text-align:center;
}
</style>